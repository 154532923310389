/**
 * @fileoverview A collection of shared resources between control utils 3d.
 */

import {NormalizedLandmark} from 'google3/third_party/mediapipe/web/solutions/utils/transform_utils/landmark';
import * as THREE from 'three';

/**
 * Converts a landmark to a vector in the THREE scene
 */
export function landmarkToVector(point: NormalizedLandmark): THREE.Vector3 {
  // The Y and Z orientations are flipped in three.js compared to the y and z
  // orientations in solutions
  return new THREE.Vector3(point.x, -point.y, -point.z);
}

/**
 * Returns a copy of a landmark
 */
export function copyLandmark(e: NormalizedLandmark): NormalizedLandmark {
  return {x: e.x, y: e.y, z: e.z, visibility: e.visibility};
}

const ORIGIN = new THREE.Vector3();
const PAUSE_SRC =
    'https://fonts.gstatic.com/s/i/googlematerialicons/pause/v14/white-24dp/1x/gm_pause_white_24dp.png';
const PLAY_SRC =
    'https://fonts.gstatic.com/s/i/googlematerialicons/play_arrow/v14/white-24dp/1x/gm_play_arrow_white_24dp.png';

/**
 * ViewerWidget configuration
 */
export interface ViewerWidgetConfig {
  backgroundColor?: number;
  fovInDegrees?: number;
  isRotating?: boolean;
  rotationSpeed?: number;
}

const DEFAULT_VIEWER_WIDGET_CONFIG: Required<ViewerWidgetConfig> = {
  backgroundColor: 0,
  fovInDegrees: 75,
  isRotating: true,
  rotationSpeed: .1,
};

/**
 * Makes it so that a configuration has optional fields on the fields in the
 * Viewer Widget config rather than required.
 */
export type BaseRequired<T> =
    Pick<Required<T>, Exclude<keyof Required<T>, keyof ViewerWidgetConfig>>&
    ViewerWidgetConfig;

/**
 * Base Class for viewing widgets. Sets up THREE.js and is useless by itself
 */
export class ViewerWidget {
  protected readonly camera: THREE.PerspectiveCamera;
  protected readonly renderer: THREE.WebGLRenderer;
  protected readonly scene: THREE.Scene;
  protected readonly container: HTMLDivElement;
  protected readonly config: Required<ViewerWidgetConfig>;
  private distance: number = 150;
  protected rotation: number = 0;
  protected disposeQueue: THREE.BufferGeometry[] = [];
  protected removeQueue: THREE.Object3D[] = [];

  constructor(parent: HTMLElement, config: ViewerWidgetConfig = {}) {
    this.config = {...DEFAULT_VIEWER_WIDGET_CONFIG, ...config};

    this.container = document.createElement('div');
    this.container.classList.add('viewer-widget-js');

    const canvas = document.createElement('canvas');
    this.container.appendChild(canvas);
    parent.appendChild(this.container);
    const parentBox = parent.getBoundingClientRect();
    this.addPausePlay(this.container);

    this.camera = new THREE.PerspectiveCamera(
        this.config.fovInDegrees, parentBox.width / parentBox.height, 1);
    this.camera.position.z = this.distance;
    this.camera.lookAt(ORIGIN);

    this.renderer =
        new THREE.WebGLRenderer({canvas, alpha: true, antialias: true});
    this.renderer.setClearColor(
        new THREE.Color(this.config.backgroundColor), .5);
    this.renderer.setSize(
        Math.floor(parentBox.width), Math.floor(parentBox.height));
    window.addEventListener('resize', () => {
      const box = this.container.getBoundingClientRect();
      this.renderer.setSize(Math.floor(box.width), Math.floor(box.height));
    });
    this.scene = new THREE.Scene();

    this.setMouseDrag();
  }

  protected render() {
    this.renderer.render(this.scene, this.camera);
  }

  protected requestFrame() {
    window.requestAnimationFrame(() => {
      if (this.config.isRotating) {
        this.rotation += this.config.rotationSpeed;
        this.camera.position.x = Math.sin(this.rotation) * this.distance;
        this.camera.position.z = Math.cos(this.rotation) * this.distance;
        this.camera.lookAt(ORIGIN);
      }
      this.render();
    });
  }

  private setMouseDrag() {
    const el = this.renderer.domElement;
    const elWidth = el.getBoundingClientRect().width;
    el.onmousedown = (event: MouseEvent) => {
      event.preventDefault();
      const speed = this.config.rotationSpeed;
      const origRotation = this.rotation;
      this.config.rotationSpeed = 0;

      const mouseMove = (e: MouseEvent) => {
        e.preventDefault();
        const rotation = 2 * Math.PI * (event.offsetX - e.offsetX) / elWidth;
        const distance =
            Math.hypot(this.camera.position.x, this.camera.position.z);
        this.rotation = origRotation + rotation;
        this.camera.position.x = Math.sin(this.rotation) * distance;
        this.camera.position.z = Math.cos(this.rotation) * distance;
        this.camera.lookAt(ORIGIN);
      };
      const mouseUp = (e: MouseEvent) => {
        e.preventDefault();
        el.removeEventListener('mousemove', mouseMove);
        this.config.rotationSpeed = speed;
        el.removeEventListener('mouseup', mouseUp);
      };

      el.addEventListener('mousemove', mouseMove);
      document.addEventListener('mouseup', mouseUp);
    };
  }

  private addPausePlay(parent: HTMLElement) {
    const button = document.createElement('img');
    button.classList.add('controls');
    button.src = this.config.isRotating ? PAUSE_SRC : PLAY_SRC;

    button.onclick = () => {
      if (this.config.isRotating) {
        button.src = PLAY_SRC;
        this.config.isRotating = false;
      } else {
        button.src = PAUSE_SRC;
        this.config.isRotating = true;
      }
    };

    parent.appendChild(button);
  }

  protected clearResources() {
    for (const e of this.removeQueue) {
      if (e.parent) e.parent.remove(e);
    }
    this.removeQueue = [];
    for (const e of this.disposeQueue) {
      e.dispose();
    }
    this.disposeQueue = [];
  }

  protected getDistance() {
    return this.distance;
  }

  setDistance(distance: number) {
    this.distance = distance;
    this.camera.position.x = Math.sin(this.rotation) * this.distance;
    this.camera.position.z = Math.cos(this.rotation) * this.distance;
    this.camera.lookAt(ORIGIN);
  }

  protected getCanvasPosition(position: THREE.Vector3): THREE.Vector3 {
    const size = this.renderer.domElement.getBoundingClientRect();
    const vector = position.clone().project(this.camera);
    // Converts from NDC ([-1, 1]) to canvas space ([0, canvas.width])
    vector.x = Math.round((0.5 + vector.x * 0.5) * size.width);
    // Converts from NDC ([-1, 1]) to canvas space ([0, canvas.height])
    vector.y = Math.round((0.5 - vector.y * 0.5) * size.height);
    vector.z = 0;
    return vector;
  }
}
