/**
 * @fileoverview A collection of generally useful functions for 3D controls and
 * data.
 */

import {NormalizedLandmark, NormalizedLandmarkList} from 'google3/third_party/mediapipe/web/solutions/utils/transform_utils/landmark';

type Matrix = number[][];

/**
 * Converts a landmark into a homogenous column vector.
 */
export function landmarkToColumnVector(landmark: NormalizedLandmark): Matrix {
  return [[landmark.x], [landmark.y], [landmark.z], [1]];
}
goog.exportSymbol('landmarkToColumnVector', landmarkToColumnVector);

/**
 * Converts a column vector to a Normalized Landmark.
 */
export function columnVectorToLandmark(v: Matrix): NormalizedLandmark {
  return ({
    x: v[0][0],
    y: v[1][0],
    z: v[2][0],
  });
}
goog.exportSymbol('columnVectorToLandmark', columnVectorToLandmark);

/**
 * Given a set of points, a transform matrix, and a projection matrix, returns a
 * list of landmarks in normalized device coordinates (NDC).
 */
export function convertToNDC(
    points: Matrix|NormalizedLandmarkList, transform: Matrix,
    projection: Matrix): NormalizedLandmarkList {
  let vectors: Matrix = [[], [], [], []];
  if (points.length === 0) {
    return [];
  }
  if (!Array.isArray(points[0])) {
    for (const landmark of points) {
      const cVector = landmarkToColumnVector(landmark as NormalizedLandmark);
      vectors[0].push(cVector[0][0]);
      vectors[1].push(cVector[1][0]);
      vectors[2].push(cVector[2][0]);
      vectors[3].push(cVector[3][0]);
    }
  } else {
    vectors = points as Matrix;
  }

  vectors = matrixMultiply(projection, matrixMultiply(transform, vectors));
  const result: NormalizedLandmarkList = [];
  for (let i = 0; i < vectors[0].length; i++) {
    result.push({
      x: vectors[0][i] / vectors[3][i],
      y: vectors[1][i] / vectors[3][i],
      z: vectors[2][i] / vectors[3][i],
    });
  }
  return result;
}
goog.exportSymbol('convertToNDC', convertToNDC);

/**
 * Multiplies two number matrices together and returns the result.
 */
export function matrixMultiply(a: Matrix, b: Matrix) {
  const aNumRows = a.length, aNumCols = a[0].length, bNumCols = b[0].length,
        bNumRows = b.length, m = [] as Matrix;

  if (aNumCols !== bNumRows) {
    throw new Error('Row Column Mismatch');
  }

  for (let r = 0; r < aNumRows; ++r) {
    m.push([]);
    for (let c = 0; c < bNumCols; ++c) {
      m[r].push(0);
      for (let i = 0; i < aNumCols; ++i) {
        m[r][c] += a[r][i] * b[i][c];
      }
    }
  }
  return m;
}
goog.exportSymbol('matrixMultiply', matrixMultiply);

/**
 * Makes a projection matrix for a perspective camera from a set of parameters.
 */
export function makeProjectionMatrix(
    fov: number, near: number, far: number, aspectRatio: number = 1): Matrix {
  const f = 1 / Math.tan(fov * Math.PI / 360);
  const denom = 1 / (near - far);
  return [
    [f / aspectRatio, 0, 0, 0],
    [0, f, 0, 0],
    [0, 0, (near + far) * denom, 2 * denom * far * near],
    [0, 0, -1, 0],
  ];
}
goog.exportSymbol('makeProjectionMatrix', makeProjectionMatrix);

/**
 * Results object for extractFromXYZUVArray
 */
export interface ExtractionResultsXYZUV {
  textureCoords: number[];
  vertexCoords: number[][];
}

/**
 * Extracts a positional vector list and a texture array from a flat array.
 */
export function extractFromXYZUVArray(buffer: ArrayLike<number>):
    ExtractionResultsXYZUV {
  const vertexCoords: number[][] = [[], [], [], []];
  const textureCoords: number[] = [];
  for (let i = 0; i < buffer.length; i += 5) {
    vertexCoords[0].push(buffer[i]);
    vertexCoords[1].push(buffer[i + 1]);
    vertexCoords[2].push(buffer[i + 2]);
    vertexCoords[3].push(1);

    textureCoords.push(buffer[i + 3]);
    textureCoords.push(buffer[i + 4]);
  }

  return ({
    textureCoords,
    vertexCoords,
  });
}
goog.exportSymbol('extractFromXYZUVArray', extractFromXYZUVArray);
